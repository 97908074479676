<template lang="pug">
.animation__group(
  :class='{ "is-bottom": isTimeline }',
  ref='animationGroup',
  :style='animationStyleObject',
  v-click-outside='onClickOutside'
)
  .animation__header(:class="{'header--extra-padding-left': !showAnimationOptions}")
    p.title(v-html='animationHeaderTitle')

    button.js-close-animation(
      v-if="!showAnimationOptions"
      type='button'
      @click='closeAnimationModal'
    )
      i.icon.icon-cancel
    div.animation-option__carousel(
      v-else
      :class="{'animation-carousel--disabled': isAnimationCarouselDisabled}"
      @click="onClickAnimationOptionCarousel"
    )
      button.carousel-icon.icon-left-arrow(
        type="button"
        :class="{'carousel-button--disabled': isCarouselPrevButtonDisabled}"
        @click="moveCarousel('prev')"
      )
      VueSlickCarousel.carousel__list(
        v-if="animationOption.length"
        ref="animationCarousel"
        v-bind="slickOptions"
        key="animation-carousel"
      )
        button.carousel__list-item(
          v-for='option in animationOption'
          :key="option.value"
          type='button',
          :class='{ "is-active": activeTab === option.value }',
          v-html='option.name',
        )
      button.carousel-icon.icon-right-arrow(
        type="button"
        :class="{'carousel-button--disabled': isCarouselNextButtonDisabled}"
        @click="moveCarousel('next')"
      )

  div.animation-tab__list-container(:class="{'container--no-scroll': isNoScrollAnimationList, 'container--has-scroll': hasScrollAnimationList }")
    ul.animation-tab__list(ref="animationScrollContainer")
      li.animation-tab__item-container(
        v-for='(transition, index) in animationListByActiveType'
        :key="transition.value"
        @click="onClickAnimationItemContainer(transition.value)"
      )
        AnimationTabItem(
          :color-class="animationTypeColorClass"
          :is-transition='isTransition',
          :transition='transition',
          :selected-transition='selectedTransition',
          :active-tab='activeTab',
          @change-animation='changeAnimation($event, index)',
        )

  div.animation-modal__footer(v-if="showAnimationFooter")
    AnimationTabDirection(
      v-if="hasTransition && directionList.length"
      :color-class="animationTypeColorClass"
      :direction-list='directionList',
      :selected-transition='selectedTransition',
      :is-modal="true"
      @change-direction='changeDirection'
    )
    AnimationTabSpeed(
      v-if='showAnimationSpeed',
      :class="{'animation-tab--extra-margin-top': directionList.length}"
      :selected-transition='selectedTransition',
      :active-tab='getActiveAnimationList.type',
      :is-modal="true"
    )
    AnimationTabDuration(
      v-if='showAnimationDuration',
      :class="{'animation-tab--extra-margin-top': directionList.length || showAnimationSpeed}"
      :selectedTransition='selectedTransition',
      :activeTab='getActiveAnimationList.type',
      :is-modal="true"
    )
</template>

<script>
/* eslint-disable */
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';
import { isEmpty, disallowSplitText, isUnsupportedEffectExist } from '@/assets/scripts/utilities';
import vClickOutside from 'v-click-outside';

import AnimationTabDirection from './children/AnimationTabDirection.vue';
import AnimationTabSpeed from './children/AnimationTabSpeed.vue';
import AnimationTabItem from './children/AnimationTabItem.vue';
import AnimationTabDuration from './children/AnimationTabDuration.vue';

import helperMixin from '@/components/mixins/helper-mixins';
import slickMixin from '@/components/mixins/slick-mixins';
/**
 * TODO: remove commented codes related to animation by line blocker for multiple gradient text Element
 */
export default {
  name: 'AnimationContainer',
  directives: {
    clickOutside: vClickOutside.directive,
  },
  mixins: [helperMixin, slickMixin],
  data() {
    return {
      directionList: [],
      activeTab: 'default',
      activeAnimationTabIndex: 0,
      isCarouselNextButtonDisabled: false,
      isCarouselPrevButtonDisabled: false,
      showByLetterOption: true,

      animationOption: [],
      animationType: [
        {
          type: 'in',
          name: 'Entrance',
          list: [],
          class: 'is-green',
        },
        {
          type: 'during',
          name: 'Emphasis',
          list: [],
          class: 'is-orange',
        },
        {
          type: 'out',
          name: 'Exit',
          list: [],
          class: 'is-red',
        },
      ],
      animationHeight: 300,
      animationWidth: 300,
      directionListPosition: '',
    };
  },
  components: {
    AnimationTabDirection,
    AnimationTabSpeed,
    AnimationTabItem,
    AnimationTabDuration,
  },
  computed: {
    ...mapState(['animationList', 'transitionList', 'showStoryboardOverlay']),
    ...mapState('canvasElements', ['activeElementsIds', 'scenesList']),
    ...mapGetters(['isLiteMode']),
    ...mapGetters(['getActiveAnimationList', 'getAnimationListDirection']),
    ...mapGetters('canvasElements', [
      'getActiveElements',
      'getCanvasElements',
      'getCanvasElementById',
      'getScenesElementsTransition',
    ]),
    activeElement() {
      return this.getActiveElements[0];
    },
    showAnimationOptions() {
      return (
        !this.isLiteMode &&
        this.isText &&
        this.getActiveAnimationList.type !== 'during' &&
        !this.isTransition
      );
    },
    showAnimationSpeed() {
      return (
        !this.isTransition &&
        this.selectedTransition.value !== '' &&
        this.selectedTransition.value !== 'none' &&
        this.getActiveAnimationList.type === 'during'
      );
    },
    showAnimationDuration() {
      return (
        !this.isTransition &&
        this.selectedTransition.value !== '' &&
        this.selectedTransition.value !== 'none' &&
        this.getActiveAnimationList.type !== 'during'
      );
    },
    selectedTransition() {
      const id = this.getActiveAnimationList.id;
      // console.log('index is', index)
      let value = '';
      let direction = '';
      let option = 'default';
      let duration = 1;
      const animateType = this.getActiveAnimationList.type;

      if (!id) return { value, direction, duration };
      if (animateType === 'transition') {
        value = this.scenesList.byId[id].transition
          ? this.scenesList.byId[id[0]].transition.value
          : '';
        direction = this.scenesList.byId[id].transition
          ? this.scenesList.byId[id[0]].transition.direction
          : '';
      } else {
        //- if there is more than 1 active elements
        //- and they have different animation
        //- set selectedTransition as 'multiple'

        let tempValue = '';
        let tempDirection = '';
        let tempOption = '';
        let tempDuration = '';

        for (let i = 0; i < id.length; i++) {
          const elementId = id[i];

          const timelineSettings = this.getCanvasElementById(elementId).timeline_settings;

          if (animateType === 'in') {
            value = timelineSettings.animateInValue ? timelineSettings.animateInValue : 'none';
            direction = timelineSettings.animateInDirection;
            option = timelineSettings.animateInOption;
            duration = timelineSettings.animateInDuration;
          } else if (animateType === 'during') {
            value = timelineSettings.animateDuringValue
              ? timelineSettings.animateDuringValue
              : 'none';
            direction = timelineSettings.animateDuringDirection;
            duration = timelineSettings.animateDuringSpeed;
          } else if (animateType === 'out') {
            value = timelineSettings.animateOutValue ? timelineSettings.animateOutValue : 'none';
            direction = timelineSettings.animateOutDirection;
            option = timelineSettings.animateOutOption;
            duration = timelineSettings.animateOutDuration;
          }

          // when it's first loop set the tempvalue and direction
          // if it's not first loop, value and direction is different
          // exit the loop and set as multiple
          // console.table({
          //   tempValue,
          //   value,
          //   tempDirection,
          //   direction,
          //   tempOption,
          //   option,
          //   tempDuration,
          //   duration
          // })
          // need to separate this because duration shouldn't affect the showing of multiple direction
          if (i !== 0 && tempDuration !== duration) {
            duration = 1;
          } else {
            tempDuration = duration;
          }
          if (
            i !== 0 &&
            !(tempValue === value && tempDirection === direction && tempOption === option)
          ) {
            value = 'multiple';
            direction = '';
            option = 'default';
            break;
          } else {
            tempValue = value;
            tempDirection = direction;
            tempOption = option;
          }
        }
      }

      return { value, direction, duration };
    },
    animationStyleObject() {
      const styleObject = {};
      const position = this.getActiveAnimationList.position;
      let top = position.y - position.height + 20;
      let left = position.x + position.width / 2;
      let bottom = null;
      let right = 'auto';

      // console.log('this.tempValue.position.position ', this.getActiveAnimationList.position.position, this.$refs.animationGroup )

      if (this.isTimeline) {
        left = position.x - this.animationWidth / 2;
        // to ensure you can still see the direction list on the left, need to set minimum of 260px + offset 10px
        if (left < 270) {
          left = 270;
        }
      }

      // if left position causing the box to be over the window
      // move it
      if (this.$refs.animationGroup) {
        const animationGroupWidth = this.$refs.animationGroup.clientWidth;
        if (left + animationGroupWidth > window.innerWidth) {
          left = window.innerWidth - animationGroupWidth - 10;
        }
      }

      if (this.isTransition) {
        // put it above the button on storyboard
        // .animation__group = 375px
        top = !position.hasTransition
          ? position.y - 100 - position.height / 2
          : position.y + 116 - position.height / 2;
        // position.x is the starting point - negate half of container width and button width for center position
        left = position.x + position.width;
        const sceneListWidth = document.querySelector('.scenes').clientWidth;
        const storyboardOverlayHeight = document.querySelector('.storyboard-overlay').clientHeight;
        const leftBuffer = sceneListWidth - 414 - position.width * 2;
        const topBuffer = storyboardOverlayHeight - 345;

        if (left > leftBuffer) left = leftBuffer;
        if (top > topBuffer) top = topBuffer;

        if (this.showStoryboardOverlay) {
          left = left + 13; // for storyboard positioning
        }

        this.directionListPosition = left < 260 ? 'right' : '';
      } else if (this.isLiteMode) {
        // for animate in lite mode
        left =
          document.getElementsByClassName('assets-navigation')[0].clientWidth +
          document.getElementsByClassName('assets-sidebar')[0].clientWidth +
          1;

        const animationGroupHeight =
          (this.$refs.animationGroup && this.$refs.animationGroup.clientHeight) || 300;
        // console.log('top', top, animationGroupHeight, window.innerHeight);
        if (top + animationGroupHeight + 50 > window.innerHeight) {
          top = null;
          bottom = 50;
        }
      }

      styleObject.left = `${left}px`;

      if (top < 20) top = 20;

      styleObject.top = `${top}px`;
      if (bottom) {
        styleObject.top = 'auto';
        styleObject.bottom = `${bottom}px`;
      }

      return styleObject;
    },
    animationHeaderTitle() {
      let text = '';

      if (this.isTransition) text = 'Transition';
      if (this.getActiveAnimationList.type === 'in')
        text = `Entrance${this.isLiteMode ? ' Animation' : ''}`;
      if (this.getActiveAnimationList.type === 'during')
        text = `Emphasis${this.isLiteMode ? ' Animation' : ''}`;
      if (this.getActiveAnimationList.type === 'out')
        text = `Exit${this.isLiteMode ? ' Animation' : ''}`;

      return text;
    },
    isTimeline() {
      // getActiveAnimationList.position.position = 'bottom' means it's from timeline
      return this.getActiveAnimationList.position.position === 'bottom';
    },
    isTransition() {
      // getActiveAnimationList.position.position = 'left' means it's from right sidebar
      return this.getActiveAnimationList.type === 'transition';
    },
    isText() {
      for (let i = 0; i < this.getActiveElements.length; i++) {
        if (this.getActiveElements[i].type !== 'texts') {
          return false;
        }
      }
      return true;
    },
    // isTextGradient() {
    //   let hasTextGradient = false;
    //   for (let i = 0; i < this.getActiveElements.length; i++) {
    //     if (this.getActiveElements[i].type === 'texts' && this.getActiveElements[i].data.textGradient.isEnabled) {
    //       hasTextGradient = true;
    //       break;
    //     }
    //   }
    //   return this.isText && hasTextGradient;
    // },
    hasNonTextElements() {
      let hasNonText = this.getActiveElements.findIndex((el) => el.type !== 'texts') >= 0;

      return hasNonText;
    },
    animationListByActiveType() {
      let activeList = [];

      if (this.getActiveAnimationList.type === 'transition') {
        activeList = this.transitionList[0].list;
      } else {
        const activeType = this.animationType.find(
          (type) => type.type === this.getActiveAnimationList.type
        );

        activeList = activeType.list;

        if (this.hasNonTextElements) {
          activeList = activeType.list.filter((type) => !type.onlyText);
        }

        // if (this.isTextGradient) {
        //   activeList = activeList.filter(type => !type.noGradientText);
        // }

        if (this.activeTab === 'per-text') {
          activeList = activeList.filter((type) => !type.noByLetter);
        } else {
          activeList = activeList.filter((type) => !type.onlyByLetter);
        }
      }

      return activeList;
    },
    isMultipleAnimationOption() {
      return this.animationOption.length > 1;
    },
    slickOptions() {
      return {
        arrows: false,
        dots: false,
        draggable: false,
        focusOnSelect: false,
        infinite: false,
        speed: 500,
        centerMode: !this.isMultipleAnimationOption,
        slidesToShow: 1,
        slidesToScroll: 1,
      };
    },
    hasTransition() {
      return this.selectedTransition && this.selectedTransition.value !== 'none';
    },
    showAnimationFooter() {
      return (
        (this.hasTransition && this.directionList.length) ||
        this.showAnimationSpeed ||
        this.showAnimationDuration
      );
    },
    isNoScrollAnimationList() {
      return (
        (!this.isLiteMode && !this.isTimeline) ||
        !this.showAnimationFooter ||
        this.showStoryboardOverlay
      );
    },
    hasScrollAnimationList() {
      return (this.isLiteMode && !this.showStoryboardOverlay) || this.isTimeline;
    },
    isAnimationCarouselDisabled() {
      if (!this.activeElement) return false;

      const unsupportedEffectExist = isUnsupportedEffectExist(this.activeElement.data);
      return unsupportedEffectExist;
    },
    animationTypeColorClass() {
      let colorClass;

      if (this.getActiveAnimationList.type === 'in') {
        colorClass = 'is-green';
      } else if (this.getActiveAnimationList.type === 'during') {
        colorClass = 'is-orange';
      } else if (this.getActiveAnimationList.type === 'out') {
        colorClass = 'is-red';
      }

      return colorClass;
    },
  },
  methods: {
    ...mapMutations([
      'setCurrentTime',
      'closeActiveAnimationList',
      'setActiveAnimationList',
      'setIsShowAnimationModal',
      'setShowColorTab',
    ]),
    ...mapMutations('canvasElements', [
      'updateSceneTransition',
      'updateCanvasElementTimelineSettings',
      'setSelectedAnimationDirection',
    ]),
    ...mapActions('canvasElements', ['setAllActiveElementsTimeline', 'saveProjectScene']),
    ...mapActions('canvasHistory', ['catchHistory']),
    setupAnimationOption() {
      let animationOption = [];

      if (this.showByLetterOption) {
        // disabled text animation per-line
        // ISSUE: if the text is loaded but the font is not ready, it will split the content which is already on the DOM (using default generic font)
        // then when the browser finally loaded the custom font, it will now apply the font style which will push the contents due to different font text spacing.

        animationOption.push({
          name: 'By Letter',
          value: 'per-text',
        });

        animationOption.push({
          name: 'By Line',
          value: 'per-line',
        });

        animationOption.push({
          name: 'By Word',
          value: 'per-word',
        });
      }

      animationOption.push({
        name: 'As One Object',
        value: 'default',
      });

      this.animationOption = animationOption;
    },
    setupCarouselSlide() {
      if (!this.isMultipleAnimationOption || !this.$refs.animationCarousel) return;

      // it will slide to the slide where the active tab exist
      const activeTabIndex = this.animationOption.findIndex(option => option.value === this.activeTab)
      this.$refs.animationCarousel.goTo(activeTabIndex);

      this.activeAnimationTabIndex = activeTabIndex;
      if (this.activeAnimationTabIndex === 0) {
        this.isCarouselPrevButtonDisabled = true;
      } else if (this.activeAnimationTabIndex === 3) {
        this.isCarouselNextButtonDisabled = true;
      }
    },
    changeAnimation(animation, index) {
      let { value, direction, directionList } = animation;
      const option = this.activeTab;

      if (value !== 'none' && typeof directionList !== 'undefined') {
        // Update directionList only if needed
        this.directionList = directionList;
      }

      if (this.isTransition) {
        this.catchHistory('scene');
        const sceneId = this.getActiveAnimationList.id;

        this.updateSceneTransition({
          id: sceneId,
          value: value,
          direction: direction,
        });

        this.saveProjectScene({ sceneId });
      } else {
        this.catchHistory('element');
        const type = this.getActiveAnimationList.type;

        const firstId = this.getActiveAnimationList.id[0];

        if (type === 'in') {
          this.setCurrentTime(0);
        } else if (type === 'during') {
          const animateInEnds =
            this.getCanvasElementById(firstId).timeline_settings.animateInDuration +
            this.getCanvasElementById(firstId).time_in;
          this.setCurrentTime(animateInEnds);
        } else if (type === 'out') {
          this.setCurrentTime(this.getCanvasElementById(firstId).timeline_settings.animateOutStart);
        }

        const animation = { type, value, direction, option };
        this.setAllActiveElementsTimeline(animation);
        this.$root.$emit('canvas-container-reset-timeline');
      }

      this.moveScroller(index);
    },
    changeDirection(direction) {
      this.catchHistory('element');
      // console.log('changeDirection this.selectedTransition.value,', this.selectedTransition.value);

      this.setSelectedAnimationDirection(direction);
      this.changeAnimation({
        value: this.selectedTransition.value,
        direction,
      });
    },
    changeAnimationOption(value) {
      // if (this.isTextGradient && value !== 'default') {
      //   // Reset to none
      //   this.changeAnimation({
      //     value: 'none',
      //     direction: '',
      //   })
      // }

      this.activeTab = value;
      // console.log('changeAnimationOption', value);
      const type = this.getActiveAnimationList.type;

      // since some of the per-text is not available for default
      // and vice versa,
      // need to go back to None if user change the option
      let selectedAnimation = this.selectedTransition.value;
      const doesAnimationExist =
        this.animationListByActiveType.findIndex((anim) => anim.value === selectedAnimation) > -1;
      // console.log('change animation', doesAnimationExist, selectedAnimation);

      for (let i = 0; i < this.activeElementsIds.length; i++) {
        const id = this.activeElementsIds[i];
        const update = {
          id,
        };

        if (type === 'in') {
          update.animateInOption = value;
          if (!doesAnimationExist) update.animateInValue = '';
        } else if (type === 'out') {
          update.animateOutOption = value;
          if (!doesAnimationExist) update.animateOutValue = '';
        }

        this.updateCanvasElementTimelineSettings(update);
      }
      this.$root.$emit('canvas-container-reset-timeline');
    },
    moveCarousel(type) {
      if (type === 'prev') {
        const activeIndex = this.activeAnimationTabIndex - 1;

        this.activeAnimationTabIndex = activeIndex;
        this.changeAnimationOption(this.animationOption[activeIndex].value);
        this.$refs.animationCarousel.prev();
      }

      if (type === 'next') {
        const activeIndex = this.activeAnimationTabIndex + 1;

        this.activeAnimationTabIndex = activeIndex;
        this.changeAnimationOption(this.animationOption[activeIndex].value);
        this.$refs.animationCarousel.next();
      }

      // ------------------------------------------------------
      // Setting whether the carousel button should be disabled
      // ------------------------------------------------------
      if (!this.isMultipleAnimationOption) {
        this.isCarouselPrevButtonDisabled = true;
        this.isCarouselNextButtonDisabled = true;
        return;
      }

      if (this.activeAnimationTabIndex === 0) {
        this.isCarouselPrevButtonDisabled = true;
      } else if (this.activeAnimationTabIndex === 3) {
        this.isCarouselNextButtonDisabled = true;
      } else {
        this.isCarouselPrevButtonDisabled = false;
        this.isCarouselNextButtonDisabled = false;
      }
    },
    onClickOutside(e, el) {
      // console.log('Click outside', event, el)
      const isSceneTransition =
        e?.path?.findIndex((path) => {
          return (
            path.className &&
            typeof path.className === 'string' &&
            path.className.indexOf('btn-scene-transition') > -1
          );
        }) > -1;
      const isTimeline =
        e?.path?.findIndex((path) => {
          return (
            path.className &&
            typeof path.className === 'string' &&
            path.className.indexOf('timeline__path') > -1
          );
        }) > -1;
      const isAnimationControl =
        e?.path?.findIndex((path) => {
          return (
            path.className &&
            typeof path.className === 'string' &&
            path.className.indexOf('btn-open-animate') > -1
          );
        }) > -1;
      const isAnimationDirection =
        e?.path?.findIndex((path) => {
          return (
            path.className &&
            typeof path.className === 'string' &&
            path.className.indexOf('animation__direction') > -1
          );
        }) > -1;
      const isAnimationInLite =
        e?.path?.findIndex((path) => {
          return (
            path.className &&
            typeof path.className === 'string' &&
            path.className.indexOf('category-item-lite__button') > -1
          );
        }) > -1;

      // if user is clicking any other button that's supposedly open animation control
      // don't close the animation list
      if (
        !isTimeline &&
        !isAnimationControl &&
        !isSceneTransition &&
        !isAnimationDirection &&
        !isAnimationInLite
      ) {
        this.closeAnimationModal();
      }
    },
    closeAnimationModal() {
      this.closeActiveAnimationList();
      this.setIsShowAnimationModal(false);
    },
    setActiveTab() {
      const { type, id } = this.getActiveAnimationList;
      let activeTab = 'default';

      if (!isEmpty(id) && id.length && (type === 'in' || type === 'out')) {
        // if there is multiple elements and they are different type
        // just set to default
        for (let i = 0; i < id.length; i += 1) {
          const element = this.getCanvasElementById(id[i]);
          const { animateInOption, animateOutOption } = element.timeline_settings;

          // for the first loop, set the active tab
          // after that, check if it's different
          if (type === 'in') {
            if (i !== 0 && activeTab !== animateInOption) {
              activeTab = 'default';
            } else {
              activeTab = animateInOption;
            }
          }
          if (type === 'out') {
            if (i !== 0 && activeTab !== animateOutOption) {
              activeTab = 'default';
            } else {
              activeTab = animateOutOption;
            }
          }
        }
      }

      this.activeTab = activeTab;
    },
    setupAutoScroll() {
      const findSelectedAnimationIndex = this.animationListByActiveType.findIndex((animation) => animation.value === this.selectedTransition.value);
      this.moveScroller(findSelectedAnimationIndex);
    },
    moveScroller(order) {
      // orders are to track the selected box positions & move the scroll according to where is the box
      const isSecondOrder = order > 3;
      const isThirdOrder = order > 7;
      const isFourthOrder = order > 11;

      let scrollPositionY = 0; // if its the first order / first 4 boxes at top, then scroll all the way to top

      if (isFourthOrder) scrollPositionY = 135;
      else if (isThirdOrder) scrollPositionY = 70;
      else if (isSecondOrder) scrollPositionY = 25;

      // used setTimeout to cover span time where scroller hasn't loaded yet because of class binding
      setTimeout(() => {
        const scroller = this.$refs.animationScrollContainer;
        scroller.scrollTop = scrollPositionY;
      }, 50);
    },
    onClickAnimationOptionCarousel() {
      if (!this.isAnimationCarouselDisabled) return;
      this.alertWarn('', "The effects you applied doesn't support this animation setting.", 8000, 'TopCenterNotif'); // eslint-disable-line
    },
    onClickAnimationItemContainer(animationItemValue) {
      const isTransitionWithValidation = animationItemValue === 'peek' || animationItemValue === 'snake';
      const isDisabled = this.isAnimationCarouselDisabled && isTransitionWithValidation;

      if (!isDisabled) return;
      this.alertWarn('', "The effects you applied doesn't support this animation setting.", 8000, 'TopCenterNotif'); // eslint-disable-line
    },
  },
  beforeMount() {
    this.animationType[0].list = this.animationList[0].list;
    this.animationType[1].list = this.animationList[1].list;
    this.animationType[2].list = this.animationList[2].list;
  },
  mounted() {
    this.directionList = this.getAnimationListDirection(
      this.getActiveAnimationList.type,
      this.selectedTransition.value
    );
    if (this.directionList && this.directionList.length === 0) {
      const transitionIndex = this.transitionList[0].list.findIndex(
        (t) => t.value === this.selectedTransition.value
      );
      if (transitionIndex >= 0) {
        this.directionList = this.transitionList[0].list[transitionIndex].direction || []; // some animation has no direction
      } else {
        this.directionList = [];
      }
    }
    this.animationWidth = this.$refs.animationGroup.clientWidth;

    if (this.isTimeline) {
      this.setupAnimationOption();
      setTimeout(()=> {
        // used timeout to ensure "this.$refs.animationCarousel" exist then do setup, used @init event from vue-slick-carousel but still not receive
        this.setupCarouselSlide();
      }, 50);
    }

    if (!this.isMultipleAnimationOption && this.activeTab !== 'default') {
      this.changeAnimationOption('default');
    }

    this.setupAutoScroll();
  },
  watch: {
    getActiveAnimationList: {
      handler(val) {
        // console.log('watcher getActiveAnimationList', val);

        this.directionList = this.getAnimationListDirection(
          this.getActiveAnimationList.type,
          this.selectedTransition.value
        );

        this.setActiveTab();
      },
      deep: true,
      immediate: true,
    },
    getActiveElements: {
      handler(val) {
        // moved into watcher instead of keep it in 'setupAnimationOption()' method because current 'animationOption' also needs to grab the active tab
        // then reorder the list accordingly in first load, if keep it in method it will keep reordering that will break the carousel
        let showByLetterOption = true;
        if (this.getActiveElements.length) {
          this.getActiveElements.forEach(function (item) {
            if (typeof item.data.content !== 'undefined' && disallowSplitText(item.data.content)) {
              showByLetterOption = false;
            }
          });
        }

        this.showByLetterOption = showByLetterOption;
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>

<style lang="scss">
.animation__group {
  margin-bottom: 10px;
  background: $darkGrey600;
  width: 375px;
  position: fixed;
  z-index: 30;
  top: 100px;
  left: 200px;
  box-shadow: -21px 23px 15px 0px rgba($black, 0.15);
  border-radius: 4px;
  animation: fadeIn 0.4s cubic-bezier(0, 0, 0, 1);

  &.is-bottom {
    transform: translateY(-102%);
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  .animation-tab__list-container {
    padding-right: 25px;

    &.container--no-scroll {
      padding-right: 0;

      .animation-tab__list {
        max-height: none;
        padding: 0 20px 30px;
      }

      &.container--has-scroll {
        padding-right: 20px; // so upon showing & hide scrollbar, the animation boxes won't seems so moved
      }
    }
  }

  .animation-tab__list {
    display: flex;
    flex-wrap: wrap;
    overflow: auto;
    scroll-behavior: smooth;
    max-height: 200px;
    margin: 0;
    padding: 0 10px 20px 20px;

    .animation-tab__item-container {
      width: 25%;
      padding: 3px;
      list-style: none;

      .animation-tab__item {
        height: 75px;
      }
    }

    &::-webkit-scrollbar {
      width: 6px;
    }

    &::-webkit-scrollbar-track {
      background-color: rgba($black, 0);
    }

    &::-webkit-scrollbar-thumb {
      background-color: $darkGrey800;
    }
  }
}

.animation__header {
  background: transparent;
  border-radius: 4px 4px 0 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 25px 20px 15px;

  &.header--extra-padding-left {
    padding: 25px 30px 15px 20px;
  }

  .title {
    font-weight: 500;
    margin: 0;
    color: $light;
    text-align: left;
    padding: 0;
    border: none;
    cursor: context-menu;
    font-size: 1rem;
  }

  .js-close-animation {
    background: none;
    border: 0;
    color: $light;
    cursor: pointer;
    font-size: 1.25rem;
    height: 20px;
    width: 20px;

    &:hover {
      color: darken($light, 5%);
    }

    &:disabled {
      color: #999;
    }

    &:hover,
    &:focus {
      outline: 0;
    }
  }

  .animation-option__carousel {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    width: 100%;
    max-width: 150px;
    margin-left: 10px;

    &.animation-carousel--disabled {
      .carousel-icon {
        opacity: 0.25;
        pointer-events: none;
      }

      .carousel__list {
        pointer-events: none;
      }
    }

    .carousel-icon {
      color: $light;
      font-size: 1.5rem;
      width: 15%;
      min-width: 20px;
      padding: 0;

      &.carousel-button--disabled {
        opacity: 0.25;
        pointer-events: none;
      }

      &:hover {
        color: $lightGrey700;
      }
    }

    .carousel__list {
      width: 70%;

      .carousel__list-item {
        font-size: 0.875rem;
        font-weight: 500;
        color: $light;
        padding: 0;

        &:not(.is-active) {
          opacity: 0.25;
          font-weight: 500;
        }
      }
    }
  }
}

.animation-modal__footer {
  background: $sidebarDarkGrey;
  padding: 15px 20px;
  box-shadow: 0px -4px 5px rgba(50, 50, 50, 0.2);

  .animation-tab__direction {
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .direction__title {
      margin: 0;
    }
  }
}

.js-animate {
  line-height: 1;
  display: block;
}
</style>
